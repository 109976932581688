import { useState, useEffect } from 'react'
import Heading from 'components/heading/heading'
import Link from 'next/link'
import { getRoute } from 'utils/helpers'
import { get } from 'utils/api'
import styles from './home-articles.module.scss'
import Image from 'components/image/image'

export default function HomeArticles() {
    const url = getRoute('buyerblog')
    const [items, setItems] = useState([])

    const init = async () => {
        const response = await get('articles/buyers?limit=3')
        if (response && response.status === 200) {
            setItems(response.data.data.articles)
        }
    }

    useEffect(init, [])

    return (
        <div className="mb72">
            <Heading tag="h2" size="30" className="mb36 pt36_">
                <Link href={url}>Tinklaraštis pirkėjams</Link>
            </Heading>
            <div className={styles.container}>
                <div className={styles.posts}>
                    {items.map(item => {
                        const postUrl = getRoute('blog-article', item)
                        return (
                            <div key={item.slug} className={styles.post}>
                                <div className={styles.image}>
                                    <Image
                                        src={item.image}
                                        alt={item.name}
                                        layout="responsive"
                                        objectFit="cover"
                                        width={416}
                                        height={250}
                                    />
                                    <Link href={postUrl}>
                                        <a aria-label={item.name}></a>
                                    </Link>
                                </div>
                                <h3>
                                    <Link href={postUrl}>
                                        <a>{item.name}</a>
                                    </Link>
                                </h3>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
